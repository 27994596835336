
import { Component, Vue, Prop } from "vue-property-decorator";
import Axios from "axios";
import { authHeader } from "../../../services/auth";
import { BASE_API_URL } from "../../../config";
import ViewDocument from '../../../common-components/ViewDocument.vue';

@Component({
    components: {
      ViewDocument
    }
  })
export default class ViewHistory extends Vue {
 /* eslint-disable */


public viewHistoryData=[]
public updatedByName: any = {}

public async getviewHistoryData() {
    try {
      let response = await Axios.get(
        BASE_API_URL + "super-admin/loans/getviewHistoryData/" + this.$route.query.id,
        { headers: authHeader() }
      );
      if (response.status === 201) {
        this.viewHistoryData = response.data;
      }
    } catch (error) {
      console.log(error);
    }
  }
  public async viewAttachment(attachments) { 
    this.$refs.ref["viewAttachment"]({attachments:attachments,loanId:this.$route.query.id,});
  }

  async mounted() {
    await this.getviewHistoryData();
  }
 

}
